body {
  color: #333;
  margin: 0;
  font-family: Lato;
}

/* front door styles */
.fd-image {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(/static/media/norway.4df730ca.jpg);
  position: absolute;
  top: 0;
  left: 0;
}

.fd-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  max-width: 1000px;
  color: white;
}

.fd-title {
  font-size: 80px;
  margin: 0;
  font-weight: 300;
}

.fd-subtitle {
  font-size: 32px;
  line-height: 38px;
  margin: 20px 0;
  font-weight: 300;
}

.fd-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 330px;
}

.fd-link {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

.fd-link:hover {
  border-bottom: 1px solid white;
  margin-bottom: -1px;
}

.fd-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  margin: 0px;
  padding: 0px;
}

.fd-footer--icons {
  display: -webkit-flex;
  display: flex;
}

.fd-footer--icon {
  padding: 0px 4px;
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
}

.fd-footer--icon svg {
  border-radius: 100%;
  cursor: pointer;
}

.fd-footer--active .fd-footer--icon {
  opacity: 0.5;
}

.fd-footer--active .fd-footer--active-icon {
  opacity: 1;
}

@media (max-width: 1020px) {
  .fd-title {
    font-size: 60px;
  }
  .fd-subtitle {
    font-size: 28px;
    line-height: 32px;
  }
  .fd-link {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .fd-title {
    font-size: 40px;
  }
  .fd-subtitle {
    font-size: 24px;
    line-height: 28px;
    margin: 40px 0;
  }
  .fd-link {
    font-size: 14px;
    line-height: 18px;
  }
}

/* reading list */
.book-grid {
  margin: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.book-grid--loading {
  background: rgb(223, 244, 222);
  width: 100vw;
  height: 100vh;
}

.book-grid--loading-text {
  font-size: 32px;
  line-height: 38px;
  padding: 16px 0 0 16px;
}

.book-square {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: calc(25% - 10px);
  margin: 5px;
  opacity: 1;
  cursor: pointer;
}

.book-square:before {
  content: "";
  display: block;
  padding-top: calc(100% - 10px);
}

.book-square:hover .book-square--overlay {
  display: block;
}

.book-square--overlay {
  display: none;
  background: rgba(100, 100, 100, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.2;
}

.book-square--content {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.book-square--title {
  font-size: 18px;
  padding: 0 16px;
  text-transform: uppercase;
}

.book-square--callout {
  font-size: 12px;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.book-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.3);
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.book-overlay--content {
  width: 400px;
  height: 400px;
  text-align: center;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.book-overlay--header {
  margin-bottom: 10px;
}

.book-overlay--title {
  margin: 0;
}

.book-overlay--close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.book-overlay--list {
  padding: 0 8px;
}

.book-overlay--list ul {
  padding: 0;
  margin: 0;
}

.book-overlay--list li {
  list-style-type: none;
  padding-top: 4px;
  margin: 0;
}

.book-overlay--list li:first-of-type {
  padding-top: 8px;
}

@media only screen and (max-width: 768px) {
  .book-grid--loading-text {
    font-size: 24px;
    line-height: 28px;
  }
  .book-square {
    width: calc(33% - 10px);
  }
}

@media only screen and (max-width: 550px) {
  .book-square {
    width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 480px) {
  .book-square {
    width: 100%;
  }
  .book-square:before {
    content: "";
    display: block;
    padding-top: calc(30% - 10px);
  }
  .book-overlay--content {
    width: 100%;
    height: 100%;
  }
  .book-square--callout {
    bottom: 8px;
    left: 8px;
  }
}

